import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import "./index.css";
import { EffectCards } from "swiper/modules";
import clickSound from "./click1.mp3";

export default function App() {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    fetch("https://flashcard-api.huedaya.com/api/nb/list")
      .then((response) => response.json())
      .then((data) => setCards(data.words))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    try {
      const audio = new Audio(clickSound);
      audio.play();
    } catch (error) {
      console.log("interact first");
    }
  }, [activeIndex]);

  return (
    <div style={{ overflow: "hidden", height: "100vh" }}>
      <div className="total">
        Norwegian Bokmål Flash Card
        <br />
        Total Words: {cards.length}
      </div>
      <Swiper
        effect={"cards"}
        grabCursor={true}
        centeredSlides={true}
        modules={[EffectCards]}
        className="mySwiper"
        onSlideChange={(test) => {
          setActiveIndex(test.activeIndex);
        }}
        loop={true}
      >
        {cards.map((card) => (
          <SwiperSlide key={card.id} data-gender={card.gender}>
            <div className="card">
              <h3>{card.word}</h3>
              <div>{card.english_translation}</div>
              {/* <p>{card.language}</p> */}
              <div style={{ opacity: 0.5, fontSize: "0.8rem" }}>
                {card.gender} / {card.popularity}
              </div>
              {/* <p>{card.popularity}</p> */}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
